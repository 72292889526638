import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row,
		Button, Form, FormGroup, Input, Label } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate } from "react-router-dom";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTechnician, getEmployerList } from "../../actions";

const Create_technician = () => {
	const history = useNavigate();
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [step, setStep] = React.useState(0);
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [employer, setEmployer] = React.useState("");
	const [employerList, setEmployerList] = React.useState([]);
	const [module_all_checks, setModuleAllChecks] = React.useState([true, true, true]);
	const [module_stock_checks, setModuleStockChecks] = React.useState([true, true, true]);
	const [module_status_checks, setModuleStatusChecks] = React.useState([true, true, true]);

	const changeFirstName = (e) => {
		setFirstName(e.target.value);
	};

	const changeLastName = (e) => {
		setLastName(e.target.value);
	};

	const changeEmail = (e) => {
		setEmail(e.target.value);
	};

	const changePhoneNumber = (e) => {
		setPhoneNumber(e.target.value);
	};

	const changeEmployer = (e) => {
		setEmployer(e.target.value);
	};

	const notify = (text, success) => {
		const options = {
			position: "top-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		};
		if (success) {
			toast.success(text, options);
		} else {
			toast.warn(text, options);
		}
	};

	const hasCountryCode = (phoneNumber) => {
		const countryCodePattern = /^\+\d+/;
		return countryCodePattern.test(phoneNumber);
	}

	const save = () => {
		let text = "";
		if (firstName === "" || lastName === "" || email === "" || phoneNumber === "" || employer === "") {
			if (firstName === "") {
				text += "First Name";
			}
			if (lastName === "") {
				if (text === "") {
					text += "Last Name";
				} else {
					text += ", Last Name";
				}
			}
			if (email === "") {
				if (text === "") {
					text += "Email";
				} else {
					text += ", Email";
				}
			}
			if (phoneNumber === "") {
				if (text === "") {
					text += "Phone Number";
				} else {
					text += ", Phone Number";
				}
			}
			if (employer === "") {
				if (text === "") {
					text += "Employer";
				} else {
					text += ", Employer";
				}
			}

			notify('Please input ' + text + '.', false);
			return;
		}

		const data = {
			first_name: firstName,
			last_name: lastName,
			email: email,
			phone_number: hasCountryCode(phoneNumber) ? phoneNumber : "+1" + phoneNumber,
			employer: employer,
		};
		createTechnician(data).then(res => {
			if (res.success) {
				notify("Technician created successfully", true);
				history(`${process.env.PUBLIC_URL}/users/list-technicians`);
			} else {
				notify(res.msg, false);
			}
		});
	};

	const handleCheckChange = (e, module, type) => {
		const value = e.target.checked;

		if(module === "all") {
			let newAll = [...module_all_checks];
			newAll[type] = value;
			setModuleAllChecks(newAll);

			let newStock = [...module_stock_checks];
			newStock[type] = value;
			setModuleStockChecks(newStock);

			let newStatus = [...module_status_checks];
			newStatus[type] = value;
			setModuleStatusChecks(newStatus);
		} else if(module === "stock") {
			let newStock = [...module_stock_checks];
			newStock[type] = value;
			setModuleStockChecks(newStock);
		} else if(module === "status") {
			let newStatus = [...module_status_checks];
			newStatus[type] = value;
			setModuleStatusChecks(newStatus);
		}
	};

	React.useEffect(() => {
		getEmployerList().then(res => {
			if(res.success) {
				setEmployerList(res.employerList);
			}
		});	
	}, []);

	return (
		<Fragment>
			<Breadcrumb title="Create Technician" parent="Customers" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5> Add Technician</h5>
							</CardHeader>
							<CardBody>
								<Tabs selectedIndex={step} onSelect={(index) => setStep(index)}>
									<TabList className="nav nav-tabs tab-coupon">
										<Tab className="nav-link">Account</Tab>
										<Tab className="nav-link">Module Permission</Tab>
									</TabList>
									<TabPanel>
										<Fragment>
											<Form className="needs-validation user-add" noValidate="">
												<h4>Account Details</h4>
												<FormGroup className="row">
													<Label className="col-xl-3 col-md-4">
														First Name <span>*</span>
													</Label>
													<div className="col-xl-8 col-md-7">
														<Input
															className="form-control"
															type="text"
															required=""
															value={firstName}
															onChange={changeFirstName}
														/>
													</div>
												</FormGroup>
												<FormGroup className="row">
													<Label className="col-xl-3 col-md-4">
														Last Name <span>*</span> 
													</Label>
													<div className="col-xl-8 col-md-7">
														<Input
															className="form-control"
															type="text"
															required=""
															value={lastName}
															onChange={changeLastName}
														/>
													</div>
												</FormGroup>
												<FormGroup className="row">
													<Label className="col-xl-3 col-md-4">
														Email <span>*</span> 
													</Label>
													<div className="col-xl-8 col-md-7">
														<Input
															className="form-control"
															type="email"
															required=""
															value={email}
															onChange={changeEmail}
														/>
													</div>
												</FormGroup>
												<FormGroup className="row">
													<Label className="col-xl-3 col-md-4">
														Phone Number <span>*</span>
													</Label>
													<div className="col-xl-8 col-md-7">
														<Input
															className="form-control"
															type="text"
															required=""
															value={phoneNumber}
															onChange={changePhoneNumber}
														/>
													</div>
												</FormGroup>
												<FormGroup className="row">
													<Label className="col-xl-3 col-md-4">
														Employer <span>*</span>
													</Label>
													<div className="col-xl-8 col-md-7">
														<select className="form-select py-2" size="1" value={employer} onChange={changeEmployer}>
															<option value="" key={-1}>Please Select Employer</option>
															{
																employerList.map((item, index) => {
																	return <option value={item} key={index}>{item}</option>
																})
															}
														</select>
													</div>
												</FormGroup>
											</Form>
											<div className="pull-right">
												<Button type="button" color="primary" onClick={save}>
													SAVE
												</Button>
											</div>
										</Fragment>
									</TabPanel>
									<TabPanel>
										<Form className="needs-validation user-add" noValidate="">
											<div className="permission-block">
												<div className="attribute-blocks">
													<Row>
														<Col xl="2" sm="3">
															
														</Col>
														<Col xl="2" sm="2">
															<Label className="d-block">
																Create
															</Label>
														</Col>
														<Col xl="2" sm="2">
															<Label className="d-block">
																View
															</Label>
														</Col>
														<Col xl="2" sm="2">
															<Label className="d-block">
																Edit
															</Label>
														</Col>
													</Row>
													<Row style={{
														marginTop: 10,
														borderTop: "1px solid #b3b3b3",
														paddingTop: 10,
														paddingBottom: 10
													}}>
														<Col xl="2" sm="3">
															<Label>All</Label>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "20px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_all_checks[0]}
																onChange={(e) => handleCheckChange(e, "all", 0)}
															/>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "18px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_all_checks[1]}
																onChange={(e) => handleCheckChange(e, "all", 1)}
															/>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "15px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_all_checks[2]}
																onChange={(e) => handleCheckChange(e, "all", 2)}
															/>
														</Col>
													</Row>
													<Row style={{
														borderTop: "1px solid #b3b3b3",
														paddingTop: 10,
														paddingBottom: 10
													}}>
														<Col xl="2" sm="3">
															<Label>Stock</Label>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "20px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_stock_checks[0]}
																disabled={module_all_checks[0]}
																onChange={(e) => handleCheckChange(e, "stock", 0)}
															/>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "18px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_stock_checks[1]}
																disabled={module_all_checks[1]}
																onChange={(e) => handleCheckChange(e, "stock", 1)}
															/>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "15px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_stock_checks[2]}
																disabled={module_all_checks[2]}
																onChange={(e) => handleCheckChange(e, "stock", 2)}
															/>
														</Col>
													</Row>
													<Row style={{
														borderTop: "1px solid #b3b3b3",
														paddingTop: 10,
														paddingBottom: 10
													}}>
														<Col xl="2" sm="3">
															<Label>Status Change</Label>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "20px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_status_checks[0]}
																disabled={module_all_checks[0]}
																onChange={(e) => handleCheckChange(e, "status", 0)}
															/>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "18px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_status_checks[1]}
																disabled={module_all_checks[1]}
																onChange={(e) => handleCheckChange(e, "status", 1)}
															/>
														</Col>
														<Col xl="2" sm="2" style={{ padding: "unset", paddingLeft: "15px" }}>
															<Input
																className="cr-checkbox"
																type="checkbox"
																checked={module_status_checks[2]}
																disabled={module_all_checks[2]}
																onChange={(e) => handleCheckChange(e, "status", 2)}
															/>
														</Col>
													</Row>
												</div>
											</div>
										</Form>
									</TabPanel>
								</Tabs>
								<ToastContainer
									position="top-right"
									autoClose={4000}
									hideProgressBar={false}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
								/>
								<ToastContainer />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Create_technician;
